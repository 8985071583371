@import '../../../../scss/theme-bootstrap';

.header-gnav-account-link {
  &__inner {
    width: 100%;
  }
  &__wrapper {
    // Inside nav subpanel
    border-top: $cr19-border-light solid 1px;
    margin-top: 10px;
    padding-top: 12px;
    @media #{$cr19-large-up} {
      margin-top: 12px;
      padding-top: 15px;
    }
    .header-gnav-account-link__loyalty {
      margin-top: 0;
      @media #{$cr19-large-up} {
        margin-top: 6px;
      }
      margin-bottom: 13px;
    }
    .header-gnav-account-link__inner {
      div.header-gnav-account-link--auth.hidden {
        display: none;
      }
      a,
      div.header-gnav-account-link--auth.hidden {
        & + .header-gnav-account-link__loyalty {
          margin-top: 6px;
        }
      }
    }
  }
  &__top {
    // PC only - offer bar right section
    opacity: 0;
    visibility: hidden;
    @media #{$cr19-large-up} {
      opacity: 1;
      visibility: visible;
    }
    .header-gnav-account-link__inner {
      @media #{$cr19-large-up} {
        display: block;
        float: #{$rdirection};
        text-align: #{$rdirection};
        white-space: nowrap;
      }
    }
    .header-gnav-account-link__loyalty {
      @media #{$cr19-large-up} {
        padding-#{$ldirection}: 50px;
      }
      &:not(.hidden) {
        @media #{$cr19-large-up} {
          display: block;
          float: #{$rdirection};
          position: relative;
        }
      }
      &:before {
        @media #{$cr19-large-up} {
          background-color: $cr19-border-dark;
          content: '';
          display: block;
          height: 20px;
          position: absolute;
          top: 1px;
          width: 1px;
          #{$ldirection}: 25px;
        }
        .header-black-white &,
        .header-black-light-transparent &,
        .header-black-dark-transparent & {
          @media #{$cr19-large-up} {
            background-color: $cr19-border-dark;
          }
        }
      }
    }
    &-sign-in {
      &:not(.hidden) {
        @media #{$cr19-large-up} {
          display: block;
          float: #{$rdirection};
        }
      }
    }
    .header-gnav-account-link__text,
    .header-gnav-account-link__loyalty-link {
      position: relative;
      white-space: nowrap;
      &:not(.hidden) {
        display: inline-block;
      }
      .header-black-white &,
      .header-black-light-transparent & .header-black-dark-transparent & {
        @include text-link--style-6;
      }
      .header-white-white &,
      .header-white-light-transparent & .header-white-dark-transparent & {
        @include text-link--style-2;
      }
      a {
        &:not(.hidden) {
          display: inline-block;
        }
        .header-black-white &,
        .header-black-light-transparent & .header-black-dark-transparent & {
          @include text-link--style-6;
        }
        .header-white-white &,
        .header-white-light-transparent & .header-white-dark-transparent & {
          @include text-link--style-2;
        }
      }
    }
  }
  &__avatar {
    border-radius: 50%;
    height: 36px;
    overflow: hidden;
    width: 36px;
    img {
      height: auto;
      max-height: 36px;
      max-width: 100%;
    }
    .header-gnav-account-link__top & {
      position: absolute;
      margin-#{$ldirection}: -56px;
    }
    .header-gnav-account-link__wrapper & {
      float: #{$rdirection};
    }
  }
  &__label {
    &.active {
      &:focus {
        outline: none;
      }
    }
  }
}
